define("discourse/plugins/discourse-activity-pub/discourse/models/activity-pub-log", ["exports", "@ember/object", "discourse/lib/ajax", "discourse/lib/ajax-error"], function (_exports, _object, _ajax, _ajaxError) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.logAdminPath = _exports.default = void 0;
  const logAdminPath = _exports.logAdminPath = "/admin/plugins/ap/log";
  class ActivityPubLog extends _object.default {}
  ActivityPubLog.reopenClass({
    list(params) {
      const queryParams = new URLSearchParams();
      if (params.order) {
        queryParams.set("order", params.order);
      }
      if (params.asc) {
        queryParams.set("asc", params.asc);
      }
      const path = logAdminPath;
      let url = `${path}.json`;
      if (queryParams.size) {
        url += `?${queryParams.toString()}`;
      }
      return (0, _ajax.ajax)(url).catch(_ajaxError.popupAjaxError);
    }
  });
  var _default = _exports.default = ActivityPubLog;
});