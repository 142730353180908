define("discourse/plugins/discourse-activity-pub/discourse/controllers/admin-plugins-activity-pub-log", ["exports", "@glimmer/tracking", "@ember/controller", "@ember/object", "@ember/object/computed", "@ember/service", "discourse/lib/ajax", "discourse/lib/ajax-error", "discourse/plugins/discourse-activity-pub/discourse/components/modal/activity-pub-log-json", "discourse/plugins/discourse-activity-pub/discourse/models/activity-pub-log"], function (_exports, _tracking, _controller, _object, _computed, _service, _ajax, _ajaxError, _activityPubLogJson, _activityPubLog) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminPluginsActivityPubLog extends _controller.default {
    static #_ = (() => dt7948.g(this.prototype, "modal", [_service.service]))();
    #modal = (() => (dt7948.i(this, "modal"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "router", [_service.service]))();
    #router = (() => (dt7948.i(this, "router"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "order", [_tracking.tracked], function () {
      return "";
    }))();
    #order = (() => (dt7948.i(this, "order"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "asc", [_tracking.tracked], function () {
      return null;
    }))();
    #asc = (() => (dt7948.i(this, "asc"), void 0))();
    loadMoreUrl = "";
    total = "";
    static #_5 = (() => dt7948.g(this.prototype, "hasLogs", [(0, _computed.notEmpty)("logs")]))();
    #hasLogs = (() => (dt7948.i(this, "hasLogs"), void 0))();
    queryParams = ["order", "asc"];
    loadMore() {
      if (!this.loadMoreUrl || this.total <= this.logs.length) {
        return;
      }
      this.set("loadingMore", true);
      return (0, _ajax.ajax)(this.loadMoreUrl).then(response => {
        if (response) {
          this.logs.pushObjects((response.logs || []).map(log => {
            return _activityPubLog.default.create(log);
          }));
          this.setProperties({
            loadMoreUrl: response.meta.load_more_url,
            total: response.meta.total,
            loadingMore: false
          });
        }
      }).catch(_ajaxError.popupAjaxError);
    }
    static #_6 = (() => dt7948.n(this.prototype, "loadMore", [_object.action]))();
    updateOrder(field, asc) {
      this.setProperties({
        order: field,
        asc
      });
    }
    static #_7 = (() => dt7948.n(this.prototype, "updateOrder", [_object.action]))();
    showJson(log) {
      this.modal.show(_activityPubLogJson.default, {
        model: {
          log
        }
      });
    }
    static #_8 = (() => dt7948.n(this.prototype, "showJson", [_object.action]))();
  }
  _exports.default = AdminPluginsActivityPubLog;
});