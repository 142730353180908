define("discourse/plugins/discourse-activity-pub/discourse/controllers/activity-pub-about", ["exports", "@ember/controller", "@ember/object/computed"], function (_exports, _controller, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ActivityPubAbout extends _controller.default {
    static #_ = (() => dt7948.g(this.prototype, "hasTagActors", [(0, _computed.notEmpty)("tagActors")]))();
    #hasTagActors = (() => (dt7948.i(this, "hasTagActors"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "hasCategoryActors", [(0, _computed.notEmpty)("categoryActors")]))();
    #hasCategoryActors = (() => (dt7948.i(this, "hasCategoryActors"), void 0))();
  }
  _exports.default = ActivityPubAbout;
});