define("discourse/plugins/discourse-activity-pub/discourse/components/activity-pub-topic-map", ["exports", "@glimmer/component", "@ember/service", "discourse/plugins/discourse-activity-pub/discourse/lib/activity-pub-utilities", "discourse/plugins/discourse-activity-pub/discourse/components/activity-pub-topic-status", "@ember/component", "@ember/template-factory"], function (_exports, _component, _service, _activityPubUtilities, _activityPubTopicStatus, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ActivityPubTopicMap extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "currentUser", [_service.service]))();
    #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "siteSettings", [_service.service]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "site", [_service.service]))();
    #site = (() => (dt7948.i(this, "site"), void 0))();
    get topic() {
      return this.args.outletArgs.topic;
    }
    get showActivityPubTopicMap() {
      return this.site.activity_pub_enabled && this.topic.activity_pub_enabled && (0, _activityPubUtilities.showStatusToUser)(this.currentUser, this.siteSettings);
    }
    static #_4 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        {{yield}}
        {{#if this.showActivityPubTopicMap}}
          <section class="topic-map__activity-pub">
            <ActivityPubTopicStatus @topic={{this.topic}} />
          </section>
        {{/if}}
      
    */
    {
      "id": "kHsz9JLN",
      "block": "[[[1,\"\\n    \"],[18,1,null],[1,\"\\n\"],[41,[30,0,[\"showActivityPubTopicMap\"]],[[[1,\"      \"],[10,\"section\"],[14,0,\"topic-map__activity-pub\"],[12],[1,\"\\n        \"],[8,[32,0],null,[[\"@topic\"],[[30,0,[\"topic\"]]]],null],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"]],[\"&default\"],false,[\"yield\",\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-activity-pub/discourse/components/activity-pub-topic-map.js",
      "scope": () => [_activityPubTopicStatus.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = ActivityPubTopicMap;
});