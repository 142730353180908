define("discourse/plugins/discourse-activity-pub/discourse/components/activity-pub-publication-type-dropdown", ["exports", "@ember/object", "@ember-decorators/component", "discourse-i18n", "select-kit/components/combo-box"], function (_exports, _object, _component, _discourseI18n, _comboBox) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const ActivityPubPublicationTypeDropdown = dt7948.c(class ActivityPubPublicationTypeDropdown extends _comboBox.default {
    nameProperty = "label";
    get content() {
      return [{
        id: "first_post",
        label: (0, _discourseI18n.i18n)("discourse_activity_pub.publication_type.label.first_post"),
        title: (0, _discourseI18n.i18n)("discourse_activity_pub.publication_type.description.first_post", {
          model_type: this.modelType
        })
      }, {
        id: "full_topic",
        label: (0, _discourseI18n.i18n)("discourse_activity_pub.publication_type.label.full_topic"),
        title: (0, _discourseI18n.i18n)("discourse_activity_pub.publication_type.description.full_topic", {
          model_type: this.modelType
        })
      }];
    }
    static #_ = (() => dt7948.n(this.prototype, "content", [_object.computed]))();
  }, [(0, _component.classNames)("activity-pub-publication-type-dropdown")]);
  var _default = _exports.default = ActivityPubPublicationTypeDropdown;
});