define("discourse/plugins/discourse-activity-pub/discourse/components/modal/activity-pub-post-admin", ["exports", "@glimmer/component", "@ember/object", "@ember/service", "discourse/components/d-button", "discourse/components/d-modal", "discourse-i18n", "discourse/plugins/discourse-activity-pub/discourse/components/activity-pub-admin-info", "discourse/plugins/discourse-activity-pub/discourse/components/activity-pub-post-actions", "discourse/plugins/discourse-activity-pub/discourse/components/modal/activity-pub-post-info", "@ember/component", "@ember/template-factory"], function (_exports, _component, _object, _service, _dButton, _dModal, _discourseI18n, _activityPubAdminInfo, _activityPubPostActions, _activityPubPostInfo, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ActivityPubPostAdminModal extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "modal", [_service.service]))();
    #modal = (() => (dt7948.i(this, "modal"), void 0))();
    get title() {
      return (0, _discourseI18n.i18n)("post.discourse_activity_pub.admin.title", {
        post_number: this.post.post_number
      });
    }
    get post() {
      return this.args.model.post;
    }
    get topic() {
      return this.post.topic;
    }
    showInfo() {
      this.modal.show(_activityPubPostInfo.default, {
        model: {
          post: this.post
        }
      });
    }
    static #_2 = (() => dt7948.n(this.prototype, "showInfo", [_object.action]))();
    static #_3 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <DModal
          @closeModal={{@closeModal}}
          @title={{this.title}}
          class="activity-pub-topic-modal activity-pub-post-admin-modal"
        >
          <:body>
            <div class="control-group">
              <label>{{i18n
                  "post.discourse_activity_pub.info.group_actors"
                }}</label>
              <div class="controls">
                <ActivityPubAdminInfo
                  @post={{this.post}}
                  @topic={{this.topic}}
                  @context="post"
                />
              </div>
            </div>
            <div class="control-group">
              <label>{{i18n "post.discourse_activity_pub.actions.label"}}</label>
              <div class="controls">
                <ActivityPubPostActions @post={{this.post}} />
              </div>
            </div>
          </:body>
          <:footer>
            <DButton
              @icon="circle-info"
              @label="post.discourse_activity_pub.info.label"
              @action={{this.showInfo}}
              class="show-info"
            />
          </:footer>
        </DModal>
      
    */
    {
      "id": "hdRhi+8W",
      "block": "[[[1,\"\\n    \"],[8,[32,0],[[24,0,\"activity-pub-topic-modal activity-pub-post-admin-modal\"]],[[\"@closeModal\",\"@title\"],[[30,1],[30,0,[\"title\"]]]],[[\"body\",\"footer\"],[[[[1,\"\\n        \"],[10,0],[14,0,\"control-group\"],[12],[1,\"\\n          \"],[10,\"label\"],[12],[1,[28,[32,1],[\"post.discourse_activity_pub.info.group_actors\"],null]],[13],[1,\"\\n          \"],[10,0],[14,0,\"controls\"],[12],[1,\"\\n            \"],[8,[32,2],null,[[\"@post\",\"@topic\",\"@context\"],[[30,0,[\"post\"]],[30,0,[\"topic\"]],\"post\"]],null],[1,\"\\n          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n        \"],[10,0],[14,0,\"control-group\"],[12],[1,\"\\n          \"],[10,\"label\"],[12],[1,[28,[32,1],[\"post.discourse_activity_pub.actions.label\"],null]],[13],[1,\"\\n          \"],[10,0],[14,0,\"controls\"],[12],[1,\"\\n            \"],[8,[32,3],null,[[\"@post\"],[[30,0,[\"post\"]]]],null],[1,\"\\n          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n      \"]],[]],[[[1,\"\\n        \"],[8,[32,4],[[24,0,\"show-info\"]],[[\"@icon\",\"@label\",\"@action\"],[\"circle-info\",\"post.discourse_activity_pub.info.label\",[30,0,[\"showInfo\"]]]],null],[1,\"\\n      \"]],[]]]]],[1,\"\\n  \"]],[\"@closeModal\"],false,[]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-activity-pub/discourse/components/modal/activity-pub-post-admin.js",
      "scope": () => [_dModal.default, _discourseI18n.i18n, _activityPubAdminInfo.default, _activityPubPostActions.default, _dButton.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = ActivityPubPostAdminModal;
});