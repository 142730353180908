define("discourse/plugins/discourse-activity-pub/discourse/components/activity-pub-attribute", ["exports", "@glimmer/component", "@ember/modifier", "@ember/object", "@ember/service", "@ember/string", "discourse/helpers/d-icon", "discourse/lib/case-converter", "discourse/lib/utilities", "discourse-i18n", "@ember/component", "@ember/template-factory"], function (_exports, _component, _modifier, _object, _service, _string, _dIcon, _caseConverter, _utilities, _discourseI18n, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const icons = {
    note: "file",
    article: "file",
    collection: "folder",
    orderedcollection: "folder",
    public: "globe",
    private: "lock",
    actor: "user"
  };
  class ActivityPubAttribute extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "toasts", [_service.service]))();
    #toasts = (() => (dt7948.i(this, "toasts"), void 0))();
    async copyURI() {
      if (!this.args.uri) {
        return;
      }
      await (0, _utilities.clipboardCopy)(this.args.uri);
      this.toasts.success({
        duration: 2500,
        data: {
          message: (0, _discourseI18n.i18n)("discourse_activity_pub.copy_uri.copied")
        }
      });
    }
    static #_2 = (() => dt7948.n(this.prototype, "copyURI", [_object.action]))();
    get icon() {
      let key = this.args.value?.toLowerCase() || "note";
      if (this.args.attribute === "actor") {
        key = "actor";
      }
      return icons[key];
    }
    get label() {
      if (!this.args.value) {
        return "";
      }
      if (this.args.attribute === "actor") {
        return this.args.value;
      } else {
        return (0, _discourseI18n.i18n)(`discourse_activity_pub.${this.args.attribute}.label.${(0, _caseConverter.camelCaseToSnakeCase)(this.args.value)}`);
      }
    }
    get classes() {
      let classes = `activity-pub-attribute ${(0, _string.dasherize)(this.args.attribute)} ${this.args.value?.toLowerCase()}`;
      if (this.args.uri) {
        classes += " copiable";
      }
      return classes;
    }
    static #_3 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <div class={{this.classes}} {{on "click" this.copyURI}} role="button">
          {{dIcon this.icon}}
          {{this.label}}
        </div>
      
    */
    {
      "id": "AeYPw0Jy",
      "block": "[[[1,\"\\n    \"],[11,0],[16,0,[30,0,[\"classes\"]]],[24,\"role\",\"button\"],[4,[32,0],[\"click\",[30,0,[\"copyURI\"]]],null],[12],[1,\"\\n      \"],[1,[28,[32,1],[[30,0,[\"icon\"]]],null]],[1,\"\\n      \"],[1,[30,0,[\"label\"]]],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[],false,[]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-activity-pub/discourse/components/activity-pub-attribute.js",
      "scope": () => [_modifier.on, _dIcon.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = ActivityPubAttribute;
});