define("discourse/plugins/discourse-activity-pub/discourse/components/activity-pub-post-info", ["exports", "@glimmer/component", "discourse/helpers/d-icon", "discourse/plugins/discourse-activity-pub/discourse/lib/activity-pub-utilities", "@ember/component", "@ember/template-factory"], function (_exports, _component, _dIcon, _activityPubUtilities, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ActivityPubPostInfo extends _component.default {
    get post() {
      return this.args.post;
    }
    get status() {
      return (0, _activityPubUtilities.activityPubPostStatus)(this.post);
    }
    get statusText() {
      return (0, _activityPubUtilities.activityPubPostStatusText)(this.post, {
        infoStatus: true,
        postActor: this.post.topic.getActivityPubPostActor(this.post.id)
      });
    }
    get statusIcon() {
      if (this.status === "not_published") {
        return "far-circle-dot";
      } else {
        return this.post.activity_pub_local ? "circle-arrow-up" : "up-right-from-square";
      }
    }
    get linkPostStatus() {
      return !this.post.activity_pub_local && this.post.activity_pub_url;
    }
    get showDelivered() {
      return !!this.post.activity_pub_delivered_at;
    }
    get deliveredText() {
      return (0, _activityPubUtilities.activityPubPostStatusText)(this.post, {
        status: "delivered",
        infoStatus: true
      });
    }
    static #_ = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <div class="activity-pub-post-info">
          <span class="activity-pub-post-status">
            {{#if this.linkPostStatus}}
              <a
                href={{this.post.activity_pub_url}}
                target="_blank"
                rel="noopener noreferrer"
              >
                {{dIcon this.statusIcon}}{{this.statusText}}
              </a>
            {{else}}
              {{dIcon this.statusIcon}}{{this.statusText}}
            {{/if}}
          </span>
          {{#if this.showDelivered}}
            <span class="activity-pub-delivered">{{dIcon
                "envelope"
              }}{{this.deliveredText}}</span>
          {{/if}}
        </div>
      
    */
    {
      "id": "1ti2TVj+",
      "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"activity-pub-post-info\"],[12],[1,\"\\n      \"],[10,1],[14,0,\"activity-pub-post-status\"],[12],[1,\"\\n\"],[41,[30,0,[\"linkPostStatus\"]],[[[1,\"          \"],[10,3],[15,6,[30,0,[\"post\",\"activity_pub_url\"]]],[14,\"target\",\"_blank\"],[14,\"rel\",\"noopener noreferrer\"],[12],[1,\"\\n            \"],[1,[28,[32,0],[[30,0,[\"statusIcon\"]]],null]],[1,[30,0,[\"statusText\"]]],[1,\"\\n          \"],[13],[1,\"\\n\"]],[]],[[[1,\"          \"],[1,[28,[32,0],[[30,0,[\"statusIcon\"]]],null]],[1,[30,0,[\"statusText\"]]],[1,\"\\n\"]],[]]],[1,\"      \"],[13],[1,\"\\n\"],[41,[30,0,[\"showDelivered\"]],[[[1,\"        \"],[10,1],[14,0,\"activity-pub-delivered\"],[12],[1,[28,[32,0],[\"envelope\"],null]],[1,[30,0,[\"deliveredText\"]]],[13],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n  \"]],[],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-activity-pub/discourse/components/activity-pub-post-info.js",
      "scope": () => [_dIcon.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = ActivityPubPostInfo;
});