define("discourse/plugins/discourse-activity-pub/discourse/components/modal/activity-pub-actor-reject", ["exports", "@ember/component", "@glimmer/component", "@ember/object", "discourse-i18n", "@ember/template-factory"], function (_exports, _component, _component2, _object, _discourseI18n, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <DModal
    @closeModal={{@closeModal}}
    @title={{this.title}}
    class="activity-pub-actor-reject-modal"
  >
    <:body>
      <div class="activity-pub-actor-reject">
        {{i18n
          "discourse_activity_pub.actor_reject.confirm"
          actor=@model.actor.name
          follower=@model.follower.handle
        }}
      </div>
    </:body>
  
    <:footer>
      <DButton
        @action={{action "reject"}}
        @label="discourse_activity_pub.actor_reject.label"
        class="btn-primary"
      />
      <DModalCancel @close={{@closeModal}} />
    </:footer>
  </DModal>
  */
  {
    "id": "joYBeG0X",
    "block": "[[[8,[39,0],[[24,0,\"activity-pub-actor-reject-modal\"]],[[\"@closeModal\",\"@title\"],[[30,1],[30,0,[\"title\"]]]],[[\"body\",\"footer\"],[[[[1,\"\\n    \"],[10,0],[14,0,\"activity-pub-actor-reject\"],[12],[1,\"\\n      \"],[1,[28,[35,3],[\"discourse_activity_pub.actor_reject.confirm\"],[[\"actor\",\"follower\"],[[30,2,[\"actor\",\"name\"]],[30,2,[\"follower\",\"handle\"]]]]]],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[]],[[[1,\"\\n    \"],[8,[39,5],[[24,0,\"btn-primary\"]],[[\"@action\",\"@label\"],[[28,[37,6],[[30,0],\"reject\"],null],\"discourse_activity_pub.actor_reject.label\"]],null],[1,\"\\n    \"],[8,[39,7],null,[[\"@close\"],[[30,1]]],null],[1,\"\\n  \"]],[]]]]]],[\"@closeModal\",\"@model\"],false,[\"d-modal\",\":body\",\"div\",\"i18n\",\":footer\",\"d-button\",\"action\",\"d-modal-cancel\"]]",
    "moduleName": "discourse/plugins/discourse-activity-pub/discourse/components/modal/activity-pub-actor-reject.hbs",
    "isStrictMode": false
  });
  class ActivityPubActorReject extends _component2.default {
    get title() {
      return (0, _discourseI18n.i18n)("discourse_activity_pub.actor_reject.modal_title", {
        actor: this.args.model.actor?.name
      });
    }
    reject() {
      const model = this.args.model;
      model.reject(model.actor, model.follower);
      this.args.closeModal();
    }
    static #_ = (() => dt7948.n(this.prototype, "reject", [_object.action]))();
  }
  _exports.default = ActivityPubActorReject;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ActivityPubActorReject);
});