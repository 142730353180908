define("discourse/plugins/discourse-activity-pub/discourse/routes/admin-plugins-activity-pub-log", ["exports", "@ember/array", "discourse/routes/discourse", "discourse/plugins/discourse-activity-pub/discourse/models/activity-pub-log"], function (_exports, _array, _discourse, _activityPubLog) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminPluginsActivityPubLogRoute extends _discourse.default {
    queryParams = {
      order: {
        refreshModel: true
      },
      asc: {
        refreshModel: true
      }
    };
    model(params) {
      return _activityPubLog.default.list(params);
    }
    setupController(controller, model) {
      controller.setProperties({
        loadMoreUrl: model.meta.load_more_url,
        total: model.meta.total,
        logs: (0, _array.A)((model.logs || []).map(actor => {
          return _activityPubLog.default.create(actor);
        }))
      });
    }
  }
  _exports.default = AdminPluginsActivityPubLogRoute;
});