define("discourse/plugins/discourse-activity-pub/discourse/components/activity-pub-topic-info", ["exports", "@glimmer/component", "@ember/service", "discourse/helpers/d-icon", "discourse/plugins/discourse-activity-pub/discourse/lib/activity-pub-utilities", "@ember/component", "@ember/template-factory"], function (_exports, _component, _service, _dIcon, _activityPubUtilities, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ActivityPubTopicInfo extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "apTopicTrackingState", [(0, _service.service)("activity-pub-topic-tracking-state")]))();
    #apTopicTrackingState = (() => (dt7948.i(this, "apTopicTrackingState"), void 0))();
    get topic() {
      return this.args.topic;
    }
    get attributes() {
      return this.apTopicTrackingState.getAttributes(this.topic.id);
    }
    get status() {
      return this.apTopicTrackingState.getStatus(this.topic.id);
    }
    get statusText() {
      return (0, _activityPubUtilities.activityPubTopicStatusText)({
        actor: this.topic.activity_pub_actor.handle,
        attributes: this.attributes,
        status: this.status,
        info: true
      });
    }
    get statusIcon() {
      if (this.status === "not_published") {
        return "far-circle-dot";
      } else {
        return this.topic.activity_pub_local ? "circle-arrow-up" : "circle-arrow-down";
      }
    }
    static #_2 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <div class="activity-pub-topic-info">
          <span class="activity-pub-topic-status">{{dIcon
              this.statusIcon
            }}{{this.statusText}}</span>
        </div>
      
    */
    {
      "id": "YNFv5+Mr",
      "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"activity-pub-topic-info\"],[12],[1,\"\\n      \"],[10,1],[14,0,\"activity-pub-topic-status\"],[12],[1,[28,[32,0],[[30,0,[\"statusIcon\"]]],null]],[1,[30,0,[\"statusText\"]]],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[],false,[]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-activity-pub/discourse/components/activity-pub-topic-info.js",
      "scope": () => [_dIcon.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = ActivityPubTopicInfo;
});