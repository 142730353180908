define("discourse/plugins/discourse-activity-pub/discourse/routes/activity-pub-about", ["exports", "discourse/lib/ajax", "discourse/lib/ajax-error", "discourse/routes/discourse"], function (_exports, _ajax, _ajaxError, _discourse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ActivityPubAboutRoute extends _discourse.default {
    model() {
      return (0, _ajax.ajax)("/ap/about.json").catch(_ajaxError.popupAjaxError);
    }
    setupController(controller, model) {
      controller.setProperties({
        categoryActors: model.category_actors,
        tagActors: model.tag_actors
      });
    }
  }
  _exports.default = ActivityPubAboutRoute;
});