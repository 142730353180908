define("discourse/plugins/discourse-activity-pub/discourse/models/activity-pub-authorization", ["exports", "discourse/lib/ajax", "discourse/lib/ajax-error"], function (_exports, _ajax, _ajaxError) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ActivityPubAuthorization {
    static remove(authId) {
      return (0, _ajax.ajax)(`/ap/auth/destroy/${authId}.json`, {
        type: "DELETE"
      }).catch(_ajaxError.popupAjaxError);
    }
  }
  _exports.default = ActivityPubAuthorization;
});