define("discourse/plugins/discourse-activity-pub/discourse/components/activity-pub-actor-follow-btn", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/object", "discourse/components/d-button", "discourse-i18n", "@ember/component", "@ember/template-factory"], function (_exports, _component, _tracking, _object, _dButton, _discourseI18n, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ActivityPubActorFollowBtn extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "followed", [_tracking.tracked], function () {
      return !!this.args.followActor.followed_at;
    }))();
    #followed = (() => (dt7948.i(this, "followed"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "following", [_tracking.tracked], function () {
      return false;
    }))();
    #following = (() => (dt7948.i(this, "following"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "followRequested", [_tracking.tracked], function () {
      return false;
    }))();
    #followRequested = (() => (dt7948.i(this, "followRequested"), void 0))();
    follow() {
      if (this.followed || this.followRequested) {
        return;
      }
      this.following = true;
      this.args.follow(this.args.actor, this.args.followActor).then(result => {
        this.followRequested = result;
        this.following = false;
      });
    }
    static #_4 = (() => dt7948.n(this.prototype, "follow", [_object.action]))();
    get icon() {
      if (this.followed) {
        return "user-check";
      } else if (this.followRequested) {
        return null;
      } else {
        return "user-plus";
      }
    }
    get i18nKey() {
      if (this.followed) {
        return "following";
      } else if (this.followRequested) {
        return "follow_requested";
      } else {
        return "follow";
      }
    }
    get title() {
      const opts = {
        actor: this.args.actor.username,
        follow_actor: this.args.followActor.username
      };
      return (0, _discourseI18n.i18n)(`discourse_activity_pub.actor_follow.${this.i18nKey}.title`, opts);
    }
    get label() {
      return (0, _discourseI18n.i18n)(`discourse_activity_pub.actor_follow.${this.i18nKey}.label`);
    }
    static #_5 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <DButton
          @action={{this.follow}}
          @icon={{this.icon}}
          @translatedLabel={{this.label}}
          @translatedTitle={{this.title}}
          @disabled={{this.following}}
          class="activity-pub-follow-actor-btn"
        />
      
    */
    {
      "id": "tI0cOAwF",
      "block": "[[[1,\"\\n    \"],[8,[32,0],[[24,0,\"activity-pub-follow-actor-btn\"]],[[\"@action\",\"@icon\",\"@translatedLabel\",\"@translatedTitle\",\"@disabled\"],[[30,0,[\"follow\"]],[30,0,[\"icon\"]],[30,0,[\"label\"]],[30,0,[\"title\"]],[30,0,[\"following\"]]]],null],[1,\"\\n  \"]],[],false,[]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-activity-pub/discourse/components/activity-pub-actor-follow-btn.js",
      "scope": () => [_dButton.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = ActivityPubActorFollowBtn;
});