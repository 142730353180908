define("discourse/plugins/discourse-activity-pub/discourse/components/activity-pub-actor", ["exports", "truth-helpers/helpers/or", "discourse/plugins/discourse-activity-pub/discourse/components/activity-pub-actor-handle-link", "discourse/plugins/discourse-activity-pub/discourse/components/activity-pub-actor-image", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _or, _activityPubActorHandleLink, _activityPubActorImage, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const ActivityPubActor = (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
    <div class="activity-pub-actor">
      {{#unless @hideImage}}
        <div class="activity-pub-actor-image">
          <ActivityPubActorImage @actor={{@actor}} @size="large" />
        </div>
      {{/unless}}
      <div class="activity-pub-actor-content">
        <div class="activity-pub-actor-name">
          {{or @actor.name @actor.username}}
        </div>
        <div class="activity-pub-actor-handle">
          <ActivityPubActorHandleLink @actor={{@actor}} />
        </div>
      </div>
    </div>
  
  */
  {
    "id": "24bqo/QW",
    "block": "[[[1,\"\\n  \"],[10,0],[14,0,\"activity-pub-actor\"],[12],[1,\"\\n\"],[41,[51,[30,1]],[[[1,\"      \"],[10,0],[14,0,\"activity-pub-actor-image\"],[12],[1,\"\\n        \"],[8,[32,0],null,[[\"@actor\",\"@size\"],[[30,2],\"large\"]],null],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"    \"],[10,0],[14,0,\"activity-pub-actor-content\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"activity-pub-actor-name\"],[12],[1,\"\\n        \"],[1,[28,[32,1],[[30,2,[\"name\"]],[30,2,[\"username\"]]],null]],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,0],[14,0,\"activity-pub-actor-handle\"],[12],[1,\"\\n        \"],[8,[32,2],null,[[\"@actor\"],[[30,2]]],null],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[\"@hideImage\",\"@actor\"],false,[\"unless\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-activity-pub/discourse/components/activity-pub-actor.js",
    "scope": () => [_activityPubActorImage.default, _or.default, _activityPubActorHandleLink.default],
    "isStrictMode": true
  }), (0, _templateOnly.default)(undefined, "activity-pub-actor:ActivityPubActor"));
  var _default = _exports.default = ActivityPubActor;
});