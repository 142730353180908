define("discourse/plugins/discourse-activity-pub/discourse/activity-pub-admin-route-map", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    resource: "admin.adminPlugins",
    path: "/plugins",
    map() {
      this.route("activityPub", {
        path: "/ap"
      }, function () {
        this.route("actor");
        this.route("actorShow", {
          path: "/actor/:actor_id"
        });
        this.route("log");
      });
    }
  };
});