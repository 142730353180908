define("discourse/plugins/discourse-activity-pub/discourse/templates/activity-pub-about", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="activity-pub-about">
    <h1>{{i18n "discourse_activity_pub.about.title"}}</h1>
    <p>{{i18n "discourse_activity_pub.about.description"}}</p>
    {{#if this.hasCategoryActors}}
      <div class="activity-pub-actors categories">
        <h3>{{i18n "discourse_activity_pub.about.categories"}}</h3>
        <div class="activity-pub-actors-list">
          {{#each this.categoryActors as |actor|}}
            <ActivityPubActorCard @actor={{actor}} />
          {{/each}}
        </div>
      </div>
    {{/if}}
    {{#if this.hasTagActors}}
      <div class="activity-pub-actors tags">
        <h3>{{i18n "discourse_activity_pub.about.tags"}}</h3>
        <div class="activity-pub-actors-list">
          {{#each this.tagActors as |actor|}}
            <ActivityPubActorCard @actor={{actor}} />
          {{/each}}
        </div>
      </div>
    {{/if}}
  </div>
  */
  {
    "id": "neg/IYzp",
    "block": "[[[10,0],[14,0,\"activity-pub-about\"],[12],[1,\"\\n  \"],[10,\"h1\"],[12],[1,[28,[35,2],[\"discourse_activity_pub.about.title\"],null]],[13],[1,\"\\n  \"],[10,2],[12],[1,[28,[35,2],[\"discourse_activity_pub.about.description\"],null]],[13],[1,\"\\n\"],[41,[30,0,[\"hasCategoryActors\"]],[[[1,\"    \"],[10,0],[14,0,\"activity-pub-actors categories\"],[12],[1,\"\\n      \"],[10,\"h3\"],[12],[1,[28,[35,2],[\"discourse_activity_pub.about.categories\"],null]],[13],[1,\"\\n      \"],[10,0],[14,0,\"activity-pub-actors-list\"],[12],[1,\"\\n\"],[42,[28,[37,7],[[28,[37,7],[[30,0,[\"categoryActors\"]]],null]],null],null,[[[1,\"          \"],[8,[39,8],null,[[\"@actor\"],[[30,1]]],null],[1,\"\\n\"]],[1]],null],[1,\"      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[41,[30,0,[\"hasTagActors\"]],[[[1,\"    \"],[10,0],[14,0,\"activity-pub-actors tags\"],[12],[1,\"\\n      \"],[10,\"h3\"],[12],[1,[28,[35,2],[\"discourse_activity_pub.about.tags\"],null]],[13],[1,\"\\n      \"],[10,0],[14,0,\"activity-pub-actors-list\"],[12],[1,\"\\n\"],[42,[28,[37,7],[[28,[37,7],[[30,0,[\"tagActors\"]]],null]],null],null,[[[1,\"          \"],[8,[39,8],null,[[\"@actor\"],[[30,2]]],null],[1,\"\\n\"]],[2]],null],[1,\"      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[13]],[\"actor\",\"actor\"],false,[\"div\",\"h1\",\"i18n\",\"p\",\"if\",\"h3\",\"each\",\"-track-array\",\"activity-pub-actor-card\"]]",
    "moduleName": "discourse/plugins/discourse-activity-pub/discourse/templates/activity-pub-about.hbs",
    "isStrictMode": false
  });
});