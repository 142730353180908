define("discourse/plugins/discourse-activity-pub/discourse/components/modal/activity-pub-log-json", ["exports", "@ember/component", "@glimmer/component", "@glimmer/tracking", "@ember/object", "discourse/lib/later", "discourse/lib/utilities", "@ember/template-factory"], function (_exports, _component, _component2, _tracking, _object, _later, _utilities, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <DModal
    @closeModal={{@closeModal}}
    @title={{i18n "admin.discourse_activity_pub.log.json.title"}}
    class="activity-pub-json-modal"
  >
    <:body>
      <div class="activity-pub-json-modal-header">
        <div class="activity-pub-json-modal-title">
          {{htmlSafe
            (i18n
              "admin.discourse_activity_pub.log.json.logged_at"
              logged_at=(formatDate
                @model.log.created_at format="medium" leaveAgo="true"
              )
            )
          }}
        </div>
        <div class="activity-pub-json-modal-buttons">
          {{#if this.copied}}
            <span class="activity-pub-json-copy-status success">
              {{i18n "admin.discourse_activity_pub.log.json.copy.success"}}
            </span>
          {{/if}}
          <DButton
            @action={{this.copyToClipboard}}
            @icon="copy"
            @label="admin.discourse_activity_pub.log.json.copy.label"
            @title="admin.discourse_activity_pub.log.json.copy.title"
            class="activity-pub-json-copy-btn btn-default"
          />
        </div>
      </div>
      <pre class="activity-pub-json-display">{{this.jsonDisplay}}</pre>
    </:body>
  </DModal>
  */
  {
    "id": "tzG1UGtL",
    "block": "[[[8,[39,0],[[24,0,\"activity-pub-json-modal\"]],[[\"@closeModal\",\"@title\"],[[30,1],[28,[37,1],[\"admin.discourse_activity_pub.log.json.title\"],null]]],[[\"body\"],[[[[1,\"\\n    \"],[10,0],[14,0,\"activity-pub-json-modal-header\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"activity-pub-json-modal-title\"],[12],[1,\"\\n        \"],[1,[28,[35,4],[[28,[37,1],[\"admin.discourse_activity_pub.log.json.logged_at\"],[[\"logged_at\"],[[28,[37,5],[[30,2,[\"log\",\"created_at\"]]],[[\"format\",\"leaveAgo\"],[\"medium\",\"true\"]]]]]]],null]],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,0],[14,0,\"activity-pub-json-modal-buttons\"],[12],[1,\"\\n\"],[41,[30,0,[\"copied\"]],[[[1,\"          \"],[10,1],[14,0,\"activity-pub-json-copy-status success\"],[12],[1,\"\\n            \"],[1,[28,[35,1],[\"admin.discourse_activity_pub.log.json.copy.success\"],null]],[1,\"\\n          \"],[13],[1,\"\\n\"]],[]],null],[1,\"        \"],[8,[39,8],[[24,0,\"activity-pub-json-copy-btn btn-default\"]],[[\"@action\",\"@icon\",\"@label\",\"@title\"],[[30,0,[\"copyToClipboard\"]],\"copy\",\"admin.discourse_activity_pub.log.json.copy.label\",\"admin.discourse_activity_pub.log.json.copy.title\"]],null],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,\"pre\"],[14,0,\"activity-pub-json-display\"],[12],[1,[30,0,[\"jsonDisplay\"]]],[13],[1,\"\\n  \"]],[]]]]]],[\"@closeModal\",\"@model\"],false,[\"d-modal\",\"i18n\",\":body\",\"div\",\"htmlSafe\",\"formatDate\",\"if\",\"span\",\"d-button\",\"pre\"]]",
    "moduleName": "discourse/plugins/discourse-activity-pub/discourse/components/modal/activity-pub-log-json.hbs",
    "isStrictMode": false
  });
  class ActivityPubLogJson extends _component2.default {
    static #_ = (() => dt7948.g(this.prototype, "copied", [_tracking.tracked], function () {
      return false;
    }))();
    #copied = (() => (dt7948.i(this, "copied"), void 0))();
    get jsonDisplay() {
      return JSON.stringify(this.args.model.log.json, null, 4);
    }
    copyToClipboard() {
      (0, _utilities.clipboardCopy)(this.args.model.log.json);
      this.copied = true;
      (0, _later.default)(() => {
        this.copied = false;
      }, 3000);
    }
    static #_2 = (() => dt7948.n(this.prototype, "copyToClipboard", [_object.action]))();
  }
  _exports.default = ActivityPubLogJson;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ActivityPubLogJson);
});