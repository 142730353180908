define("discourse/plugins/discourse-activity-pub/discourse/components/activity-pub-actor-card", ["exports", "@glimmer/component", "discourse-i18n", "discourse/plugins/discourse-activity-pub/discourse/components/activity-pub-actor-model", "discourse/plugins/discourse-activity-pub/discourse/components/activity-pub-follow-btn", "discourse/plugins/discourse-activity-pub/discourse/components/activity-pub-handle", "@ember/component", "@ember/template-factory"], function (_exports, _component, _discourseI18n, _activityPubActorModel, _activityPubFollowBtn, _activityPubHandle, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ActivityPubActorCard extends _component.default {
    get followersPath() {
      return `/ap/local/actor/${this.args.actor.id}/followers`;
    }
    static #_ = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <div class="activity-pub-actor-card">
          <div class="activity-pub-actor-card-top">
            <ActivityPubActorModel @actor={{@actor}} />
            <div class="follower-count activity-pub-actor-metadata">
              <a
                href={{this.followersPath}}
                class="activity-pub-actor-follower-count"
              >
                {{i18n
                  "discourse_activity_pub.about.actor.follower_count"
                  count=@actor.follower_count
                }}
              </a>
            </div>
          </div>
          <div class="activity-pub-actor-card-bottom">
            <ActivityPubHandle @actor={{@actor}} @hideLink={{true}} />
            <ActivityPubFollowBtn @actor={{@actor}} @type="follow" />
          </div>
        </div>
      
    */
    {
      "id": "cZYIhfhJ",
      "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"activity-pub-actor-card\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"activity-pub-actor-card-top\"],[12],[1,\"\\n        \"],[8,[32,0],null,[[\"@actor\"],[[30,1]]],null],[1,\"\\n        \"],[10,0],[14,0,\"follower-count activity-pub-actor-metadata\"],[12],[1,\"\\n          \"],[10,3],[15,6,[30,0,[\"followersPath\"]]],[14,0,\"activity-pub-actor-follower-count\"],[12],[1,\"\\n            \"],[1,[28,[32,1],[\"discourse_activity_pub.about.actor.follower_count\"],[[\"count\"],[[30,1,[\"follower_count\"]]]]]],[1,\"\\n          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,0],[14,0,\"activity-pub-actor-card-bottom\"],[12],[1,\"\\n        \"],[8,[32,2],null,[[\"@actor\",\"@hideLink\"],[[30,1],true]],null],[1,\"\\n        \"],[8,[32,3],null,[[\"@actor\",\"@type\"],[[30,1],\"follow\"]],null],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"@actor\"],false,[]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-activity-pub/discourse/components/activity-pub-actor-card.js",
      "scope": () => [_activityPubActorModel.default, _discourseI18n.i18n, _activityPubHandle.default, _activityPubFollowBtn.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = ActivityPubActorCard;
});