define("discourse/plugins/discourse-activity-pub/discourse/components/modal/activity-pub-topic-info", ["exports", "@glimmer/component", "@ember/object", "@ember/service", "discourse/components/d-button", "discourse/components/d-modal", "discourse-i18n", "discourse/plugins/discourse-activity-pub/discourse/components/activity-pub-attributes", "discourse/plugins/discourse-activity-pub/discourse/components/activity-pub-post-info", "discourse/plugins/discourse-activity-pub/discourse/components/activity-pub-topic-info", "discourse/plugins/discourse-activity-pub/discourse/components/modal/activity-pub-topic-admin", "@ember/component", "@ember/template-factory"], function (_exports, _component, _object, _service, _dButton, _dModal, _discourseI18n, _activityPubAttributes, _activityPubPostInfo, _activityPubTopicInfo, _activityPubTopicAdmin, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ActivityPubTopicInfoModal extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "modal", [_service.service]))();
    #modal = (() => (dt7948.i(this, "modal"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "currentUser", [_service.service]))();
    #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
    get topic() {
      return this.args.model.topic;
    }
    get firstPost() {
      return this.args.model.firstPost;
    }
    get title() {
      return (0, _discourseI18n.i18n)("topic.discourse_activity_pub.info.title", {
        topic_id: this.topic.id
      });
    }
    get canAdmin() {
      return this.currentUser?.staff;
    }
    showAdmin() {
      this.modal.show(_activityPubTopicAdmin.default, {
        model: {
          firstPost: this.firstPost,
          topic: this.topic
        }
      });
    }
    static #_3 = (() => dt7948.n(this.prototype, "showAdmin", [_object.action]))();
    static #_4 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <DModal
          @closeModal={{@closeModal}}
          @title={{this.title}}
          class="activity-pub-topic-modal activity-pub-topic-info-modal"
        >
          <:body>
            <div class="control-group">
              <label>{{i18n "topic.discourse_activity_pub.info.status"}}</label>
              <div class="controls">
                <ActivityPubTopicInfo @topic={{this.topic}} />
                <ActivityPubPostInfo @post={{this.firstPost}} />
              </div>
            </div>
            <div class="control-group">
              <label>{{i18n "topic.discourse_activity_pub.info.attributes"}}</label>
              <div class="controls">
                <ActivityPubAttributes
                  @topic={{this.topic}}
                  @post={{this.firstPost}}
                />
              </div>
            </div>
          </:body>
    
          <:footer>
            {{#if this.canAdmin}}
              <DButton
                @icon="gear"
                @label="topic.discourse_activity_pub.admin.label"
                @action={{this.showAdmin}}
                class="show-admin"
              />
            {{/if}}
          </:footer>
        </DModal>
      
    */
    {
      "id": "38RUyiBF",
      "block": "[[[1,\"\\n    \"],[8,[32,0],[[24,0,\"activity-pub-topic-modal activity-pub-topic-info-modal\"]],[[\"@closeModal\",\"@title\"],[[30,1],[30,0,[\"title\"]]]],[[\"body\",\"footer\"],[[[[1,\"\\n        \"],[10,0],[14,0,\"control-group\"],[12],[1,\"\\n          \"],[10,\"label\"],[12],[1,[28,[32,1],[\"topic.discourse_activity_pub.info.status\"],null]],[13],[1,\"\\n          \"],[10,0],[14,0,\"controls\"],[12],[1,\"\\n            \"],[8,[32,2],null,[[\"@topic\"],[[30,0,[\"topic\"]]]],null],[1,\"\\n            \"],[8,[32,3],null,[[\"@post\"],[[30,0,[\"firstPost\"]]]],null],[1,\"\\n          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n        \"],[10,0],[14,0,\"control-group\"],[12],[1,\"\\n          \"],[10,\"label\"],[12],[1,[28,[32,1],[\"topic.discourse_activity_pub.info.attributes\"],null]],[13],[1,\"\\n          \"],[10,0],[14,0,\"controls\"],[12],[1,\"\\n            \"],[8,[32,4],null,[[\"@topic\",\"@post\"],[[30,0,[\"topic\"]],[30,0,[\"firstPost\"]]]],null],[1,\"\\n          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n      \"]],[]],[[[1,\"\\n\"],[41,[30,0,[\"canAdmin\"]],[[[1,\"          \"],[8,[32,5],[[24,0,\"show-admin\"]],[[\"@icon\",\"@label\",\"@action\"],[\"gear\",\"topic.discourse_activity_pub.admin.label\",[30,0,[\"showAdmin\"]]]],null],[1,\"\\n\"]],[]],null],[1,\"      \"]],[]]]]],[1,\"\\n  \"]],[\"@closeModal\"],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-activity-pub/discourse/components/modal/activity-pub-topic-info.js",
      "scope": () => [_dModal.default, _discourseI18n.i18n, _activityPubTopicInfo.default, _activityPubPostInfo.default, _activityPubAttributes.default, _dButton.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = ActivityPubTopicInfoModal;
});